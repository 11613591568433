import React from 'react'

export default () => (
  <section className="padding-md">
    <h1 className="h1 mb-40">
    Unique portfolio of Lloyd’s capacity
    </h1>

    <div className="u-color-emperor">
      <p className="lead mb-20">
        Helios is the only listed investment company offering investors growth and returns from exposure to Lloyd’s of London.
      </p>

      <p>
      Helios Underwriting plc, provides investors with exposure to the Lloyds insurance market through an actively managed portfolio of syndicate capacity. So far, Helios has acquired over 75 companies with a total underwriting capacity of over £100 million. Helios has outperformed the Lloyd’s market by 9% over the past five years.
      </p>

      <p className="mb-40">
      Helios is an AIM-listed investment company which offers growth and returns by building a portfolio of capacity of syndicates at Lloyd’s and by acquiring high quality Limited Liability Vehicles (‘LLVs’).  These LLVs replaced the famous Lloyd’s Names at the start of this century, and are now coming up for sale as their owners age or decide to exit.
      </p>

      
    </div>

  </section>
)
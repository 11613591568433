import React from 'react'

import styles from 'components/aside.module.scss'

export default ({children}) => (
  <aside className={styles.aside}>
    <div className={styles.content}>
      {children}
    </div>
  </aside>
)

import React from 'react'
import { formatDate } from 'helpers'

class Document extends React.Component {
  render() {
    const filetype = this.props.file.split('.').pop()
    return (
      <a href={this.props.file} className="c-document">
        <div className="c-document__title">{this.props.title}</div>
        <div className="c-document__file-info">
          <span className="c-document__file-type">{filetype}&ensp;</span>
          <span className="c-document__date">{formatDate(this.props.date)}</span>
        </div>
      </a>
    )
  }
}

export default Document
import React from 'react'
import Divider from 'components/divider'
import styles from 'components/highlights.module.scss'

export default props => (
  <div className={styles.block}>
    <Divider color="white"></Divider>
    <h2 className={styles.title}>{props.title}</h2>
    <div>{props.children}</div>
  </div>
)

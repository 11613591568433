import React from 'react'
import {Link} from 'gatsby'

import styles from 'components/call-to-action.module.scss'

export default props => (
  <div className={styles.block}>
    <h2 className={styles.title}>Continue reading</h2>
    <div className={styles.linkBlock}>
      <Link className={styles.link} to={props.link}>{props.title}</Link>
    </div>
  </div>
)
import React from 'react'

import styles from './portrait-quote.module.scss'

export default props => (
  <div className={styles.block} style={{backgroundImage: `url(${props.image})`}}>
    <div className={styles.content}>
      <div className={styles.quote}>
        {props.children}
      </div>
      <div className={styles.name}>
        {props.name}
      </div>
      <div className={styles.title}>
        {props.title}
      </div>
    </div>
  </div>
)
import React from 'react'
import Header from 'components/header'
import Aside from 'components/aside'

import styles from 'layouts/column-layout.module.scss'

export default props => (
  <main>
    <Header layout="column" />
    <div className={styles.wrapper}>
      <div className={styles.content}>{props.children}</div>
      <Aside>{props.aside}</Aside>
    </div>
  </main>
)

import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import ColumnLayout from 'layouts/column-layout'

// Page sections
import KeyDocuments from './_key-documents'
import Intro from './_intro'
import Highlights from './_highlights'
import PortraitQuote from 'components/portrait-quote'
import CTA from 'components/call-to-action'

class Overview extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Overview | Helios Underwriting</title>
        </Helmet>
        <ColumnLayout aside={<KeyDocuments />}>
          <Intro />

          <Highlights/>

          <CTA
            title="Portfolio and capacity"
            link="/about-helios-underwriting/portfolio-and-capacity"
          />

        </ColumnLayout>
      </Layout>
    )
  }
}


export default Overview